import * as React from "react";

import { Row, Col, Image, Container } from "react-bootstrap";
import circle from "../assets/homepay_landscape.png";
import { BrowserRouter } from "react-router-dom";
import Routes from "../Routes";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, createMuiTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        homepay_image_left: {
            left: 0,
            height: "100vh",
            width: "45vw",
            position: "relative",
            marginLeft: 0
        }
    })
);

const AppLandscape = function () {
    const classes = useStyles(createMuiTheme);

    return (
        <Container>
            <Row className="form-row">
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="col-image">
                    <Image className={classes.homepay_image_left} src={circle} alt="" />
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </Col>
            </Row>
        </Container>
    );
};

export default AppLandscape;


import PlayStore from "../../../assets/play_store.png";
import AppStore from "../../../assets/app_store.png";
import { withRouter } from "react-router-dom";
import React from "react";


import { Typography, Container } from '@material-ui/core';
import { translate } from '../../../i18n';
import { Row, Col } from "react-bootstrap";

const RedirectButtonsStore = function () {

    return (
        <Container>
            <Row className="row-margin">
                <Col>
                    <Typography variant="body1">
                        {translate('homepay')}
                    </Typography>
                </Col>
            </Row>
            <Row className="row-margin">
                <Col>
                    <Typography variant="h6">
                        {translate('homepayDescription')}
                    </Typography>
                </Col>
            </Row>
            <Row className="row-margin">
                <Col>
                    <Typography variant="h6">
                        {translate('downloadNow')}
                    </Typography>
                </Col>
            </Row>
            <Row className="row-margin">
                <Col >
                    <a href="https://play.google.com/store/apps/details?id=br.com.mega.portaldeclientes">
                        <img className="play-store" src={PlayStore} alt=""
                            width="80%"
                        />
                    </a>
                </Col>
                <Col>
                    <a href="https://apps.apple.com/br/app/homepay/id1448730793">
                        <img className="app-store" src={AppStore} alt=""
                            width="80%"
                        />
                    </a>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(RedirectButtonsStore);
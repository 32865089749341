import React, { useState } from "react";
import { firebase } from "../../config/firebase";


import { withRouter, RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';



import CreatePasswordForm from './components/CreatePasswordForm';
import { Row } from "react-bootstrap";

/**
 * Interfaces
 */
interface IParams { id: string };

interface Props extends RouteComponentProps<IParams> { }

const CreatePassword = function (props: Props) {
  const valuesUrl = queryString.parse(props.location.search);

  let codeResetPassword = String(valuesUrl.oobCode);

  useState(() => {

    if (!codeResetPassword) {
      props.history.push('/error-password');
    } else {
      verifyCodeReset();
    }
  });

  async function verifyCodeReset() {
    await firebase.auth().checkActionCode(codeResetPassword).then(
      (result) => {
        return null;
      }, err => {
        props.history.push('/error-password');
      }
    );
  }

  async function updatePassword(password: string) {

    await firebase.auth().confirmPasswordReset(codeResetPassword, password).then(
      (result) => {
        props.history.push('/success-password');
      }, err => {
        props.history.push('/error-password');
      }
    );
  }

  return (
    <Row>
      <CreatePasswordForm updatePassword={updatePassword} />
    </Row>

  );
}

export default withRouter(CreatePassword);
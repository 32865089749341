import React from "react";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { translate } from '../../i18n';
import { Row, Col } from 'react-bootstrap';

const UpdatePasswordError = function () {
  return (
    <Row className="row-content">
      <Col md={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
        <Typography variant="subtitle1">{translate('passwordResetCodeExpired')}</Typography>
      </Col>
    </Row>
  );
}

export default withRouter(UpdatePasswordError);
import I18n from 'i18n-js';
import en from './en-US';
import pt from './pt-BR';

I18n.fallbacks = true;

I18n.translations = {
    'en-US': en,
    'pt-BR': pt,
};

const setLanguageToI18n = () => {
    const language = navigator.language;
    const iHaveThisLanguage = I18n.translations.hasOwnProperty(
        language,
    );

    iHaveThisLanguage
        ? (I18n.locale = language)
        : (I18n.defaultLocale = 'pt-BR');
};

setLanguageToI18n();

export const translate = (key: string) => I18n.t(key);
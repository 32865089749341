import * as React from "react";
import * as ReactDOM from "react-dom";
import "./config/firebase";
import "./App.scss";


import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
registerServiceWorker();

const rootEl = document.getElementById("root") as HTMLElement;
ReactDOM.render(<App />, rootEl);


import * as React from "react";
import { observer } from "mobx-react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import blue from "@material-ui/core/colors/blue";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import "typeface-montserrat";
import VerifyDevice from './utils/verify_device';
import verifyOrientation from './utils/verify_orientation';
import AppLandscape from './components/AppLandscape'
import AppPortrait from './components/AppPortrait'

const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
const breakpoints = createBreakpoints({ values: breakpointValues });


const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontFamily: '"Montserrat", sans-serif',
        color: "black",
        textAlign: "start",
        [breakpoints.only("xs")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "130%" : "100%" : "130%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
        [breakpoints.only("sm")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "120%" : "105%" : "120%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
        [breakpoints.only("md")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "180%" : "145%" : "180%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
        [breakpoints.only("lg")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "220%" : "185%" : "220%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
        [breakpoints.only("xl")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "270%" : "225%" : "270%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
      },
      subtitle2: {
        fontFamily: '"Montserrat", sans-serif',
        textAlign: "start",
        [breakpoints.only("xs")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "70%" : "55%" : "70%",
          width: VerifyDevice() === "device" ? "100%" : "100%",
        },
        [breakpoints.only("sm")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "50%" : "35%" : "50%",
          width: VerifyDevice() === "device" ? "100%" : "100%",
        },
        [breakpoints.only("md")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "80%" : "60%" : "80%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
        [breakpoints.only("lg")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "100%" : "90%" : "100%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
        [breakpoints.only("xl")]: {
          fontSize: VerifyDevice() === "device" ? verifyOrientation() ? "100%" : "90%" : "100%",
          width: VerifyDevice() === "device" ? "100%" : "80%",
        },
      },
      caption: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 15,
        fontWeight: 700,
        textAlign: "center",
        width: "80%",
        [breakpoints.only("xs")]: {
          fontSize: "70%",
        },
        [breakpoints.only("sm")]: {
          fontSize: "70%",
        },
        [breakpoints.only("md")]: {
          fontSize: "80%",
        },
        [breakpoints.only("lg")]: {
          fontSize: "90%",
        },
        [breakpoints.only("xl")]: {
          fontSize: "90%",
        },
      },
      body1: {
        width: "100%",
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: 600,
        textAlign: "center",
        [breakpoints.only("xs")]: {
          fontSize: "100%",
          width: "100%",
        },
        [breakpoints.only("sm")]: {
          fontSize: "110%",
          width: "100%",
        },
        [breakpoints.only("md")]: {
          fontSize: "115%",
          width: "100%",
        },
        [breakpoints.only("lg")]: {
          fontSize: "150%",
          width: "90%",
        },
        [breakpoints.only("xl")]: {
          fontSize: "197%",
          width: "90%",
        },
      },
      h6: {
        [breakpoints.only("xs")]: {
          fontSize: "80%",
          width: "100%",
        },
        [breakpoints.only("sm")]: {
          fontSize: "80%",
          width: "100%",
        },
        [breakpoints.only("md")]: {
          fontSize: "90%",
          width: "100%",
        },
        [breakpoints.only("lg")]: {
          fontSize: "130%",
          width: "100%",
        },
        [breakpoints.only("xl")]: {
          fontSize: "130%",
          width: "100%",
        },
      },
      h1: {
        top: "25%",
        position: "absolute",
      }
    }
  },
  palette: {
    primary: blue
  },
});

@observer
class App extends React.Component {
  state: { orientation: boolean; device: string; originalSizeScreen: number; };

  constructor(props: any) {
    super(props);
    this.state = { orientation: true, device: "", originalSizeScreen: 0 };
  }

  componentWillMount() {
    this.setState({ device: VerifyDevice(), orientation: verifyOrientation(), originalSizeScreen: window.screen.height + window.screen.width });
  }

  componentDidMount() {
    var self = this;
    if ('onorientationchange' in window) {
      window.addEventListener("orientationchange", function () {
        self.setState({ device: VerifyDevice(), orientation: !self.state.orientation });
      }, false);
    }
  }

  public render() {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ height: 'inherit', display: 'block' }}>

          {this.state.device === "device"
            ?
            this.state.orientation ?
              <AppPortrait />
              :
              <AppLandscape />
            :
            <AppLandscape />
          }
        </div>
      </ThemeProvider>
    );
  }
}

export default App;

const verifyOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches
    ) {
        return true;
    }
    else {
        return false;
    }
};

export default verifyOrientation;
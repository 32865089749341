const en = {
  setPassword: "Set a password",
  createPassword:
    "Use the fields below to create a password. We do not want unwanted visitors, right?",
  password: "Password",
  repeatPassword: "Repeat Password",
  passwordMustCharacters: "Password must be at least 6 characters",
  save: "SAVE",
  passwordSavedSuccessfully: "Password Saved Successfully!",
  chooseYourApp: "Choose your mobile operating system and download the app.",
  later: "Later",
  homepay: "HOMEPAY: MANAGING YOUR NEW HOME IN THE PALM OF YOUR HAND!",
  homepayDescription:
    "Homepay is the ideal app for you to keep up with the evolution of your property and have documents, invoices and all the information you need at hand.",
  downloadNow: "Download now:",
  enterLeastSixDigits: "Enter at least 6 digits",
  passwordNotMatch: "The passwords do not match.",
  passwordRewrite: "Please check and type again.",
  passwordResetCodeExpired: "Your password reset code has expired."
};

export default en;

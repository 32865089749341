import React from "react";
import PlayStore from "../../../assets/play_store.png";
import AppStore from "../../../assets/app_store.png";


import { Button } from "@material-ui/core";
import { translate } from '../../../i18n';

const ModalSuccess = ({ handleClose, show }: { [key: string]: any }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-success'>
                <p className="modalPasswordTitle">{translate('passwordSavedSuccessfully')}</p>
                <p className="modalPasswordSubtitle">{translate('chooseYourApp')}</p>
                <p>
                    <a href="https://play.google.com/store/apps/details?id=br.com.mega.portaldeclientes">
                        <img className="play-store" src={PlayStore} alt=""
                            width="30%"
                        />
                    </a>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <a href="https://apps.apple.com/br/app/homepay/id1448730793">
                        <img className="app-store" src={AppStore} alt=""
                            width="30%"
                        />
                    </a>
                </p>
                <Button className="buttonCloseModalSuccess" onClick={handleClose}>{translate('later')}</Button>
            </section>
        </div>
    );
};

export default ModalSuccess;

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ModalSuccess from './components/SuccessUpdatePasswordModal';
import RedirectButtonsStore from './components/RedirectButtonsStore';
import { Row, Col, Container } from "react-bootstrap";

const UpdatePasswordSuccess = function () {

  const [showModal, setShowModal] = useState({ showSuccess: true });

  const hideModalSuccess = () => {
    setShowModal({ ...showModal, showSuccess: false });
  }

  return (
    <Container>
      <Row className="row-content">
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <RedirectButtonsStore></RedirectButtonsStore>
        </Col>
      </Row>
      <ModalSuccess show={showModal.showSuccess} handleClose={hideModalSuccess} />
    </Container>

  );
}

export default withRouter(UpdatePasswordSuccess);
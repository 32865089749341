import * as firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

// Initialize Firebase
var config = {
    apiKey: "AIzaSyB5c0pvQ8WOHd2zh9yoiQ2TWZmae1bRK0U",
    authDomain: "mega-portaldeclientes.firebaseapp.com",
    databaseURL: "https://mega-portaldeclientes.firebaseio.com",
    projectId: "mega-portaldeclientes",
    storageBucket: "mega-portaldeclientes.appspot.com",
    messagingSenderId: "846336510390"
};

firebase.initializeApp(config);

const firestore = firebase.firestore();
firestore.enablePersistence({ synchronizeTabs: true });

export { firebase, firestore };

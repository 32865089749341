
import { withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';


import { Button } from '@material-ui/core';
import VerifyDevice from '../../../utils/verify_device';



const SavePasswordButton = withStyles((theme: Theme) => ({
    root: {
        background: "transparent linear-gradient(156deg, #2C2F63 0%, #008059 100%) 0% 0% no-repeat padding-box",
        color: "white",
        fontWeight: 400,
        height: "80%",
        width: VerifyDevice() === "device" ? "100%" : "95%",
        boxShadow: "0px 13px 28px #2C2F63;",
        flexBasis: 0,
        opacity: 1,
        alignContent: "start",
        marginTop: "10%"

    },
}))(Button);

export default SavePasswordButton;
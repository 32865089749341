import React from "react";

import { withRouter } from "react-router-dom";

import RedirectButtonsStore from '../update-password-success/components/RedirectButtonsStore';
import { Row, Col } from "react-bootstrap";

const PageEmpty = function () {

  return (
    <Row className="row-content">
      <Col md={12} xl={12} lg={12} sm={12} xs={12}>
        <RedirectButtonsStore></RedirectButtonsStore>
      </Col>
    </Row>
  );
}

export default withRouter(PageEmpty);
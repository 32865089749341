
import * as React from "react";
import { Route, Switch } from "react-router-dom";

/**
 * Routes
 */
import CreatePassword from "./user/create-password/CreatePassword";
import UpdatePasswordSuccess from "./user/update-password-success/UpdatePasswordSuccess";
import UpdatePasswordError from "./user/update-password-error/UpdatePasswordError";
import PageEmpty from "./user/page-empty/PageEmpty";

const Router = () => (
    <Switch>
        <Route exact path="/reset-password/:id" component={CreatePassword} />
        <Route exact path="/" component={PageEmpty} />
        <Route exact path="/success-password" component={UpdatePasswordSuccess} />
        <Route exact path="/error-password" component={UpdatePasswordError} />
        {/* https://mega-portaldeclientes.firebaseapp.com/__/auth/action?mode=<action>&oobCode=<code> */}
    </Switch>
);

export default Router;

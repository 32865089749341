import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import SavePasswordButton from "./SavePasswordButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { translate } from '../../../i18n';
import { createStyles, Theme, createMuiTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Container, Row, Col } from "react-bootstrap";

const breakpointValues = {
    xs: 0,
    sm: 800,
    md: 1280,
    lg: 1920,
    xl: 1920,
};
const breakpoints = createBreakpoints({ values: breakpointValues });

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0),
        },
        labelTextField: {
            width: "100%",
            [breakpoints.only("xs")]: {
                fontSize: "45%",
            },
            [breakpoints.only("sm")]: {
                fontSize: "55%",
            },
            [breakpoints.only("md")]: {
                fontSize: "80%",
            },
            [breakpoints.only("lg")]: {
                fontSize: "70%",
            },
            [breakpoints.only("xl")]: {
                fontSize: "80%",
            },
        },
        textFieldPassword: {
            width: "80%",
            [breakpoints.only("xs")]: {
                width: "100%",
            },
            [breakpoints.only("sm")]: {
                fontSize: "120%",
                width: "100%",
            },
        },
        modal: {
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "30em",
            height: "18em",
            marginTop: "-9em",
            marginLeft: "-15em",
            border: "1px solid",
            backgroundColor: "white"
        },
        // sizeInputs: {
        // height: 0
        // }
    })
);


/**
 * Interfaces
 */

interface State {
    password: string;
    showPassword: boolean;
}

const FieldsPasswordForm = function ({
    updatePassword,
    password
}: {
    [key: string]: any;
}) {

    const classes = useStyles(createMuiTheme);

    const [valuesPassword, setValuesPassword] = useState({
        password: "",
        showPassword: false
    });

    const [valuesPasswordRepeat, setValuesPasswordRepeat] = useState({
        password: "",
        showPassword: false
    });

    const [errorFieldPasssword, setErrorFieldPasssword] = useState({
        helperText: "",
        error: false
    });

    const [errorFieldPassswordRepeat, setErrorFieldPassswordRepeat] = useState({
        helperText: "",
        error: false
    });

    const verifyValidFielPassword = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.value.length > 5) {
            if (valuesPasswordRepeat.password !== event.target.value && valuesPasswordRepeat.password.length > 5) {
                setErrorFieldPassswordRepeat({ helperText: translate("passwordNotMatch"), error: true });
            }
            setErrorFieldPasssword({ helperText: "", error: false });

        } else {
            setErrorFieldPasssword({
                helperText: translate('enterLeastSixDigits'),
                error: true
            });
        }
    };

    const verifyValidFielPasswordRepeat = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.value.length > 5) {
            if (valuesPassword.password === event.target.value) {
                setErrorFieldPassswordRepeat({ helperText: "", error: false });
            } else {
                setErrorFieldPassswordRepeat({ helperText: translate("passwordNotMatch"), error: true });
            }
        } else {
            setErrorFieldPassswordRepeat({
                helperText: translate('enterLeastSixDigits'),
                error: true
            });
        }
    };

    const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValuesPassword({ ...valuesPassword, password: event.target.value });

        password = valuesPassword.password;
    };

    const handleChangeRepeat = (prop: keyof State) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValuesPasswordRepeat({
            ...valuesPasswordRepeat,
            [prop]: event.target.value
        });
    };

    const handleClickShowPassword = () => {
        setValuesPassword({
            ...valuesPassword,
            showPassword: !valuesPassword.showPassword
        });
    };

    const handleClickShowPasswordRepeat = () => {
        setValuesPasswordRepeat({
            ...valuesPasswordRepeat,
            showPassword: !valuesPasswordRepeat.showPassword
        });
    };

    const handleSubmit = () => {
        if (valuesPassword.password && valuesPassword.password.length > 5) {
            if (
                valuesPasswordRepeat.password &&
                valuesPasswordRepeat.password.length > 5
            ) {
                if (valuesPassword.password === valuesPasswordRepeat.password) {
                    updatePassword(valuesPassword.password);
                }
            } else {
                setErrorFieldPassswordRepeat({
                    helperText: translate('enterLeastSixDigits'),
                    error: true
                });
            }
        } else {
            setErrorFieldPasssword({
                helperText: translate('enterLeastSixDigits'),
                error: true
            });
        }
    };

    return (
        <Container>
            <Row className="margin-form">
                <Col>
                    <Typography variant="subtitle1">{translate('setPassword')}</Typography>
                </Col>
            </Row>
            <Row className="margin-form">
                <Col>
                    <Typography variant="subtitle2">
                        {translate('createPassword')}
                    </Typography>
                </Col>
            </Row>
            <Row className="margin-form">
                <Col>
                    <Typography variant="caption">
                        {translate('password')}
                    </Typography>
                </Col>
            </Row>
            <Row className="margin-form">
                <Col >
                    <TextField
                        id="filled-create-password"
                        className={classes.textFieldPassword}
                        variant="filled"
                        type={valuesPassword.showPassword ? "text" : "password"}
                        label={<Typography className={classes.labelTextField}>{translate('passwordMustCharacters')}</Typography >}
                        value={valuesPassword.password}
                        onChange={handleChange()}
                        onInput={verifyValidFielPassword.bind(valuesPasswordRepeat.password)}
                        error={errorFieldPasssword.error}
                        helperText={errorFieldPasssword.helperText}
                        InputProps={{
                            // classes: { input: classes.sizeInputs },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleClickShowPassword}>
                                        {valuesPassword.showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                                <Visibility />
                                            )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Col>
            </Row>
            <Row className="margin-form">

                <Col>
                    <Typography variant="caption" align="justify">
                        {translate('repeatPassword')}
                    </Typography>
                </Col>
            </Row>
            <Row className="margin-form">

                <Col>
                    <TextField

                        id="filled-create-password-repeat"
                        className={classes.textFieldPassword}
                        variant="filled"
                        type={valuesPasswordRepeat.showPassword ? "text" : "password"}
                        label={<Typography className={classes.labelTextField}>{translate('repeatPassword')}</Typography >}
                        value={valuesPasswordRepeat.password}
                        onChange={handleChangeRepeat("password")}
                        onInput={verifyValidFielPasswordRepeat.bind(
                            valuesPasswordRepeat.password
                        )}
                        error={errorFieldPassswordRepeat.error}
                        helperText={errorFieldPassswordRepeat.helperText}
                        InputProps={{
                            // classes: { input: classes.sizeInputs },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleClickShowPasswordRepeat}>
                                        {valuesPasswordRepeat.showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                                <Visibility />
                                            )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Col>
            </Row>
            <Row className="margin-form button-save-margin">
                <Col xs={{ span: 6, offset: 6 }} sm={{ span: 6, offset: 6 }} md={{ span: 5, offset: 7 }} lg={{ span: 5, offset: 5 }} xl={{ span: 5, offset: 5 }}>
                    <SavePasswordButton
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {translate('save')}
                    </SavePasswordButton>
                </Col>
            </Row>
        </Container>
    );
};

export default FieldsPasswordForm;

const pt = {
  setPassword: "Defina uma senha",
  createPassword:
    "Use os campos abaixo para criar uma senha. Não queremos visitantes indesejados, não é o mesmo?",
  password: "Senha",
  repeatPassword: "Repita a senha",
  passwordMustCharacters: "A senha deve conter no mínimo 6 caracteres",
  save: "SALVAR",
  passwordSavedSuccessfully: "Senha salva com sucesso!",
  chooseYourApp:
    "Escolha o seu sistema operacional mobile e baixe o aplicativo.",
  later: "Mais tarde",
  homepay: "HOMEPAY: GESTÃO DA SUA CASA NOVA NA PALMA DA MÃO!",
  homepayDescription:
    "O Homepay é o app ideal para você acompanhar a evolução do seu imóvel e ter documentos, faturas e todas as informações que precisa sempre à mão.",
  downloadNow: "Baixe agora:",
  enterLeastSixDigits: "Digite pelo menos 6 dígitos",
  passwordNotMatch: "As senhas não coincidem.",
  passwordRewrite: "Por favor, verifique e digite reescrever.",
  passwordResetCodeExpired: "Seu código de redefinição de senha expirou."
};

export default pt;

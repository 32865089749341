import * as React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import Routes from "../Routes";
import circleMobile from "../assets/homepay_portrait.png";


const AppPortrait = function () {

    return (
        <Container>
            <Row className="margin-portrait">
                <Image className="circle-mobile" src={circleMobile} alt="" />
            </Row>
            <Row>
                <Col>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </Col>
            </Row>
        </Container>
    );
};

export default AppPortrait;


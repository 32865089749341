import React from "react";
import VerifyDevice from '../../../utils/verify_device';
import FieldsPasswordForm from './FieldsPasswordForm';

import {
  Row,
  Col
} from "react-bootstrap";
const CreatePasswordForm = function ({
  setShowModal,
  updatePassword,
  password
}: {
  [key: string]: any;
}) {
  let classRow = VerifyDevice() === "device" ? "row-content-password" : "row-content";

  return (
    <Row className={classRow}>
      <Col md={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
        <FieldsPasswordForm setShowModal={setShowModal} updatePassword={updatePassword} password={password} />
      </Col>
    </Row>

  );
}

  ;

export default CreatePasswordForm;